<template>
  <div class="form-stepper form-stepper-feed">
    <Loading :value="loading"/>
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
    >
      <div class="d-flex pb-4 align-center">
        <p class="text-h6 mb-0"><span>{{ $t('FEED.STEP_INTRO_TITLE') }}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 0)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div>
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('FEED.TITLE') }}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span>{{ values.title }}</span>
          </v-col>
        </v-row>

        <v-divider />
        <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('FEED.FIELD_AUTO_REFRESH') }}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span>{{ $t(('COMMON.'+!!values.auto_refresh).toUpperCase()) }}</span>
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('FEED.FIELD_LEGACY_HTML_TO_MD') }}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span>{{ $t(('COMMON.'+!!values.legacy_html_to_md).toUpperCase()) }}</span>
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('FEED.FIELD_MAX_CHURN_PERCENT') }}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span>{{ values.max_churn_percent }}</span>
          </v-col>
        </v-row>

      </div>

      <div class="d-flex pb-4 mt-10 align-center">
        <p class="text-h6 mb-0"><span>{{ $t('FEED.STEP_PROTOCOL_TITLE') }}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 1)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div>
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('FEED.PROTOCOL') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.protocol }}</span>
          </v-col>
        </v-row>

        <template v-if="values.protocol && values.protocol === 'http'">
          <v-divider />
          <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol_params && values.protocol_params.url">
            <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
              {{ $t('FEED.FIELD_PROTOCOL_PARAMS_URL') }}
            </v-col>
            <v-col cols="10" class="text-ellipsis text-nowrap">
              <a target="blank" :href="values.protocol_params.url">
                <v-icon class="mr-1" size="18">mdi-link</v-icon>
                {{ values.protocol_params.url }}
              </a>
            </v-col>
          </v-row>
        </template>

        <template v-if="values.protocol && values.protocol === 'ftp'">
          <v-divider />
          <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol_params && values.protocol_params.host">
            <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
              {{ $t('FEED.FIELD_PROTOCOL_PARAMS_HOST') }}
            </v-col>
            <v-col cols="10" class="text-ellipsis text-nowrap">
              <span>{{ values.protocol_params.host }}</span>
            </v-col>
          </v-row>
        </template>

        <template v-if="values.fileformat">
          <v-divider />
          <v-row class="mt-0 mb-0 text-medium">
            <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
              {{ $t('FEED.FIELD_FILEFORMAT') }}
            </v-col>
            <v-col cols="10" class="text-ellipsis text-nowrap">
              <span>{{ values.fileformat.toUpperCase() }}</span>
            </v-col>
          </v-row>
        </template>
      </div>

      <div class="d-flex pb-4 mt-10 align-center">
        <p class="text-h6 mb-0"><span>{{ $t('FEED.STEP_FIELD_TITLE') }}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 2)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div>
        <template v-for="(field, i) in values.fileformat_fields.filter(v => !v.custom)">
          <div v-if="(field.value && field.value.length > 0) || field.default_value" :key="'fir'+i">
            <v-divider />
            <v-row class="mt-0 mb-0 text-medium">
              <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
                {{ $t('INPUT.' + field.key.toUpperCase()) }}
              </v-col>
              <v-col cols="10" class="text-ellipsis text-nowrap">
                <span>{{ (field.value || []).join(', ') || field.default_value }}</span>
                <v-icon
                  class="ml-2"
                  size="12"
                  v-if="
                    (field.changes && field.changes.length > 0)
                    || field.spacing
                    || (field.excludes && field.excludes.length > 0)
                  "
                >
                  mdi-cog
                </v-icon>
              </v-col>
            </v-row>
          </div>
        </template>
      </div>

      <div class="d-flex pb-4 mt-10 align-center">
        <p class="text-h6 mb-0"><span>{{ $t('FEED.STEP_CUSTOM_FIELD_TITLE') }}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 3)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div>
        <template v-for="(field, i) in values.fileformat_fields.filter(v => v.custom)">
          <div v-if="(field.value && field.value.length > 0) || field.default_value" :key="'fir'+i">
            <v-divider />
            <v-row class="mt-0 mb-0 text-medium">
              <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
                {{ field.key }}
              </v-col>
              <v-col cols="10" class="text-ellipsis text-nowrap">
                <span>{{ (field.value || []).join(', ') || field.default_value }}</span>
                <v-icon
                  class="ml-2"
                  size="12"
                  v-if="
                    (field.changes && field.changes.length > 0)
                    || field.spacing
                    || (field.excludes && field.excludes.length > 0)
                  "
                >
                  mdi-cog
                </v-icon>
              </v-col>
            </v-row>
          </div>
        </template>
      </div>

      <div class="d-flex pb-4 mt-10 align-center">
        <p class="text-h6 mb-0"><span>{{ $t('FEED.STEP_HOOK_TITLE') }}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 3)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div>
        <template v-for="(field, i) in values.fileformat_hooks">
          <div v-if="(field.value && field.value.length > 0) || field.default_value" :key="'fir'+i">
            <v-divider />
            <v-row class="mt-0 mb-0 text-medium">
              <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
                {{ $t('INPUT.' + field.key.toUpperCase()) }}
              </v-col>
              <v-col cols="10" class="text-ellipsis text-nowrap">
                <span>{{ (field.value || []).join(', ') || field.default_value }}</span>
                <v-icon
                  class="ml-2"
                  size="12"
                  v-if="
                    (field.changes && field.changes.length > 0)
                    || field.spacing
                    || (field.excludes && field.excludes.length > 0)
                  "
                >
                  mdi-cog
                </v-icon>
              </v-col>
            </v-row>
          </div>
        </template>
      </div>

    </v-form>
    <v-divider/>

    <v-toolbar
      color="transparent"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer/>

      <v-btn
        color="success"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <v-icon class="mr-2">mdi-check</v-icon>
        <strong>{{ $t('COMMON.SAVE') }}</strong>
      </v-btn>
    </v-toolbar>

  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  name: "FormFeedStepperConfirm",
  components: {
    Loading,
  },
  props: {
    values: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    loading: false,
    valid: true,
  }),
  methods: {
    async validate() {
      if (!this.$refs.form.validate()) return
      const values = this.$clone(this.values)
      // prepare values
      delete values.examples
      delete values.count
      let list = ''
      if (typeof values.fileformat_list === 'string') {
        list = values.fileformat_list
      } else if (values.fileformat_list instanceof Array && values.fileformat_list.length > 0) {
        list = values.fileformat_list[0]
      }
      values.fileformat_list = list
      this.loading = true
      try {
        const response = await this.$services.api_programmatic.feed.create({values})
        this.$emit('validation', true)
        this.$emit('create', response.data[1])
        this.$emit('close')
      }
      catch(e) {
        this.$emit('validation', false)
        await this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      }
      finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>

</style>
